import IMask from 'imask';
import type { FocusEventHandler, MouseEventHandler } from 'react';
import { useCallback } from 'react';
import { IMaskMixin } from 'react-imask';

export enum TextfieldType {
    MULTILINE = 'multiline',
    NUMBER = 'number',
    DATE = 'date',
    EMAIL = 'email',
    IMASK_IRRATIONAL = 'imask-irr',
    IMASK_NUMBER = 'imask-number',
    URL = 'url',
    DOMAIN = 'domain',
    STRING = 'string',
    PASSWORD = 'password'
}

export interface TextFieldProps {
    clearable?: boolean;
    value?: string | number;
    defaultValue?: string | number;
    type?: TextfieldType;
    min?: number;
    max?: number;
    disabled?: boolean;
    autoFocus?: boolean;
    className?: string;
    rootClassName?: string;
    mask?: string | RegExp;
    imaskOptions?: AnyObject; //IMask.AnyMaskedOptions;
    pattern?: string;
    placeholder?: string;
    inputRef?: any;
    error?: boolean;
    icon?: SVGIcon;
    iconClick?: () => void;
    iconPos?: 'start' | 'end';
    id?: string;
    style?: CSSProperties;
    transparent?: boolean;
    onBlur?: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
    onFocus?: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
    onChange?: (val: any, e?: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onClick?: MouseEventHandler<Element>;
    onEnter?: (e: KeyboardEvent) => void;
    maxLength?: number;
    autoComplete?: 'off' | 'new-password';
}

export const UrlBlocks = {
    url: {
        mask: /[-a-zA-Z0-9()@:%_+.~#?&/=]+/
    }
};

export const definitions = {
    9: /[0-9]?/,
    f: /[A-Fa-f0-9]/,
    a: /[A-Za-z]/,
    '*': /[A-Za-z0-9]/,
    c: /[+0-9]/,
    p: /[-0-9() ]/,
    '#': /#/
};

export const blocks = {
    dd: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 31
    },
    DD: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 31
    },
    MM: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 12
    },
    YYYY: {
        mask: IMask.MaskedRange,
        from: 2020,
        to: 2030
    },
    yyyy: {
        mask: IMask.MaskedRange,
        from: 2020,
        to: 2030
    }
};

export const mobileNumberKeyboard = '[0-9]*';
export const replaceDigitsWithX = (s: string): string => s.replace(/\d/g, '0');
export const replaceDigitsWithMask = (s: string): string => s.replace(/\d/g, '0');

export const createPhoneFormatPipe = (mask: string): any =>
    IMask.createPipe({
        mask: replaceDigitsWithMask(mask),
        blocks,
        definitions
    });

export const formatPhone = (value: string | number, countryFormat: string, phoneFormat: string): string => {
    const format = countryFormat.replace(/_/g, '') + ' ' + phoneFormat.replace(/_/g, '');
    const pipeMask = createPhoneFormatPipe(format);

    return pipeMask(value);
};

export const DateMasked = IMaskMixin(({ inputRef, ...props }) => (
    <input
        inputMode='numeric'
        pattern={mobileNumberKeyboard}
        ref={inputRef as any}
        {...props}
        readOnly={false}
        type='text'
    />
));

export const NumericMasked = IMaskMixin(({ inputRef, ...props }) => (
    <input inputMode='numeric' pattern={mobileNumberKeyboard} ref={inputRef as any} {...props} />
));

export const EmailMasked = IMaskMixin(({ inputRef, ...props }) => (
    <input inputMode='email' ref={inputRef as any} type='input' {...props} />
));

// @ts-ignore
export const UrlMasked = IMaskMixin(({ inputRef, onChange, protocol, ...props }) => {
    const onPaste = useCallback(
        (event) => {
            const selection = window.getSelection();
            if (!selection.rangeCount) return true;

            let value = event.clipboardData.getData('text');

            if (!value.match(/https:\/\//gi) && !value.match(/http:\/\//gi)) {
                value = protocol ? 'http://' + value : value;
            }

            event.currentTarget.value = value;
            event.preventDefault();
            onChange({ target: { value } } as ChangeEvent<HTMLInputElement>);
        },
        [onChange, protocol]
    );

    return <input inputMode='url' ref={inputRef as any} type='url' onPaste={onPaste} {...props} onChange={onChange} />;
});

export type ITextfield = {
    focus: () => void;
    blur: () => void;
    select: () => void;
    input: () => any;
};

export const emailRe = new RegExp(/^.*@?.*\.?.*$/gi);
export const emailMask = (value): boolean => emailRe.test(value);
